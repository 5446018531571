import React,{useEffect} from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import toolkit_store from "./store/redux-store/root_store";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Provider } from "react-redux";
import ChatPayment from "./chatbot/ChatPayment";
import Stripe from "./payment/Stripe"
import BraintreeDropIn from "./payment/BraintreeDropIn";
import Square from "./payment/Square";
import Razorpay from './payment/razorpay'
import  Wixpayment  from "./chatbot/wixpayment";
import Wix3ds from "./chatbot/wix3ds"

const root = ReactDOM.createRoot(document.getElementById("root"));
if(root){
root.render(
<Provider store={toolkit_store}>
    <BrowserRouter>
      <Routes>
        <Route element={<App />} path="/chatbot" />
        <Route element={<ChatPayment />} path="/chatpayment" />
        <Route element={<Stripe />} path="/stripe" />
        <Route element={<BraintreeDropIn />} path="/paypal" />
        <Route element={<Square />} path="/square" />
        <Route element={<Razorpay />} path="/razorpay" />
        <Route element={<Wixpayment />} path="/wixpayment" />
        <Route element={<Wix3ds />} path="/wix3ds" />
      </Routes>
    </BrowserRouter>
  </Provider>
)
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
