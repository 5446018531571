import React, { useState, useEffect } from 'react';
// import logo from './logo.svg';
// import './App.css';
import axios from 'axios';

function Razorpay() {
    const [keyId, setKeyId] = useState("");
    const [keySecret, setKeySecret] = useState("");
    const [domainId, setDomainId] = useState(sessionStorage.getItem("domainId"))
    const [uuid, setUuid] = useState(localStorage.getItem("uuid"))
    function loadScript(src) {
        return new Promise((resolve) => {
            const script = document.createElement('script');
            script.src = src;
            script.onload = () => {
                resolve(true);
            };
            script.onerror = () => {
                resolve(false);
            };
            document.body.appendChild(script);
        });
    }

    async function getPaymentDetails() {
        let json
        const response = await axios.post('https://mysqlapi.worktual.co.uk/myaccount-mysql/v1/worktual/get_ChatBotPaymentIntegration_v1', {
            p_domainId: domainId,
        });

        if (response?.data?.users?.length > 0) {
            let getObj = response?.data?.users[0].filter((ele) => ele.paymentType === "Razorpay")
            console.log("getObj", getObj[0]?.secretJson)
            json = JSON.parse(getObj[0]?.secretJson)
            setKeyId(json.keyId)
            setKeySecret(json.keySecret)
        }
    }

    async function displayRazorpay() {
        const res = await loadScript(
            'https://checkout.razorpay.com/v1/checkout.js'
        );

        if (!res) {
            alert('Razorpay SDK failed to load. Are you online?');
            return;
        }

        const result = await axios.get(`http://localhost:5004/vectone-myaccount/v1/user/order_create/${domainId}`);

        if (!result) {
            alert('Server error. Are you online?');
            return;
        }
        console.log("result123",result)

        const { amount, id: order_id, currency } = result.data.data;
        console.log(">>>>>>>>>", amount, currency,keyId)
        console.log(amount, order_id, currency)
        const options = {
            key: keyId, // Enter the Key ID generated from the Dashboard
            amount: 100,
            currency: "INR",
            name: 'Worktual',
            description: 'Test Transaction',
            //   image: { logo },
            order_id: order_id,
            // callback_url:'http://localhost:5004/vectone-myaccount/v1/user/payment_sucess',
            // redirect:true,
            handler: async function (response) {
                const data = {
                    orderCreationId: order_id,
                    razorpayPaymentId: response.razorpay_payment_id,
                    razorpayOrderId: response.razorpay_order_id,
                    razorpaySignature: response.razorpay_signature,
                    sessionId: uuid
                };
                console.log("response.razorpay_payment_id",response.razorpay_payment_id)
                // const result = await axios.post('/payment/success', data);
                window.parent.postMessage({ type: 'PAYMENT_IFRAME', message: {orderId:response.razorpay_payment_id,status:'success'} }, '*');
                // const res = await fetch('http://localhost:5004/vectone-myaccount/v1/user/payment_sucess', {
                //     method: 'POST',
                //     //body: JSON.stringify({ amount: "10", currency: 'usd' })
                //     body: JSON.stringify(data)
                // });

                // alert(result.data.msg);
            },
            prefill: {
                name: '',
                email: 'example@example.com',
                contact: '9999999999',
            },
            notes: {
                address: 'Example Corporate Office',
            },
            theme: {
                color: '#008c35',
            },
        };

        const paymentObject = new window.Razorpay(options);
        paymentObject.on("payment.failed", function (response) {
            alert(response.error.metadata.payment_id);
            window.parent.postMessage({ type: 'PAYMENT_IFRAME', message: {orderId:response.error.metadata.payment_id,status:'success'} }, '*');

          });
        paymentObject.open();
        console.log("gdfg",paymentObject)

 
    }

    useEffect(() => {
        const init = async () => {
            await getPaymentDetails()
            await displayRazorpay()
        }
        init()
    }, [])

    useEffect(() => {
        setTimeout(() => {
            if (keySecret) {
                (document.getElementById("3ds_form")).click()
            }
        }, 1000);
    }, [keySecret])

    return (
        <div className='App'>
            <header className='App-header'>
                {/* <img src={logo} className='App-logo' alt='logo' /> */}
                {/* <button className='App-link' onClick={displayRazorpay}>
                    Pay 
                </button> */}
                <p id="3ds_form" onClick={displayRazorpay}>loading...</p>
            </header>
        </div>
    );
}

export default Razorpay;