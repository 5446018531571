import React, { useEffect, useState } from 'react'
import dropin from "braintree-web-drop-in"
import { Input, Button, Form } from 'antd';
import axios from 'axios';

export default function BraintreeDropIn(props) {
	// const { show, onPaymentCompleted, token, amount, setPaymentStatus } = props;
	const [braintreeInstance, setBraintreeInstance] = useState(undefined)
	const [show, setShow] = useState("")
	const [token, setToken] = useState("")
	const [amount, setAmount] = useState(10)
	const [status, setStatus] = useState("");
	const [uuid,setUuid] = useState(sessionStorage.getItem("sessionUniqueId"))
	const [domainId,setDomainId] = useState(sessionStorage.getItem("domainId"))

	const handlePayment = async (nonce) => {
		try {


			const response1 = await axios.post('https://liveurapi.worktual.co.uk/vectone-myaccount/v1/user/create_payment_ref', {
				sitecode: 'MCM',
				productcode: "EBUN",
				applicationcode: "VMUK",
				paymentagent: "VMUK",
				servicetype: "RES",
				paymentmode: "DEF",
				paymentstep: 1,
				paymentstatus: 0,
				accountid:447574457482,
				last6digitccno: "0077",
				totalamount: 10,
				currency: "GBP",
				subscriptionid: '',
				merchantid: 'uk01login',
				providercode: 'CS',
				csreasoncode: '100',
				generalerrorcode: 0,
				returnerror: 0,
				email: "",
				IPpaymentagent: '182.66.104.114',
				IPClient: '0.0.0.0',
				ReplyMessage: 'Payment Reference Created',
				// ThreeDFlag: req.bypass3ds ? false : true,
				device_type: "Chatbot",
				browser: "WEB",
				os_version: "",
				topup_url: "",
				bundle_id: 4,
				expirydate: "04",
				cardtype: "VISA"
			  });
	  
			  


			const response = await axios.post('https://liveurapi.worktual.co.uk/vectone-myaccount/v1/user/paypal_checkout', { payment_method_nonce: nonce, amount: amount,sessionId:uuid,ref_id:response1.data.topupProcessccdcsmsResp[0].ref_id,domainId:domainId });
			if (response.data.success) {
				// setPaymentStatus("Success");
				window.parent.postMessage({ type: 'PAYMENT_IFRAME', message: {orderId:response1.data.topupProcessccdcsmsResp[0].ref_id,status:'success'} }, '*');
				setStatus("succeeded");
				//onPaymentCompleted();
			} else {
				window.parent.postMessage({ type: 'PAYMENT_IFRAME', message: {orderId:response1.data.topupProcessccdcsmsResp[0].ref_id,status:'failed'} }, '*');
				// setPaymentStatus("failed");
			}
		} catch (error) {
			console.error(error);
		}
	};

	useEffect(() => {

		const _init = async () => {
			axios.get(`https://liveurapi.worktual.co.uk/vectone-myaccount/v1/user/create_client_token/${domainId}`)
				.then(response => { setToken(response.data.clientToken); setShow(true) })
				.catch(error => console.error(error));
		}
		_init()




		if (show) {
			const initializeBraintree = () => dropin.create({
				authorization: token,
				container: '#braintree-drop-in-div',
				paypal: {
					flow: 'vault',
					amount: amount,
					currency: 'GBP',
					buttonStyle: {
						layout: 'vertical',
						color: 'gold',
					},
					commit: true,
				},
			}, function (error, instance) {
				if (error)
					console.error(error)
				else
					setBraintreeInstance(instance);
			});

			if (braintreeInstance) {
				braintreeInstance
					.teardown()
					.then(() => {
						initializeBraintree();
					});
			} else {
				initializeBraintree();
			}
		}
	}, [show])

	return (
		<div className="paymentscroll scroller" style={{ display: `${show ? "block" : "none"}` }}>
			<div id={"braintree-drop-in-div"} />
			<Button
				className={"braintreePayButton"}
				type="primary"
				disabled={!braintreeInstance}
				onClick={() => {
					if (braintreeInstance) {
						braintreeInstance.requestPaymentMethod(
							(error, payload) => {
								if (error) {
									console.error(error);
								} else {
									const paymentMethodNonce = payload.nonce;
									console.log("payment method nonce", payload.nonce);
									handlePayment(payload.nonce)
								}
							});
					}
				}}
			>
				{
					"Pay"
				}
			</Button>

			{status === 'succeeded' && (
				<div>
					<h2>Payment Succeeded!</h2>
				</div>
			)}

			{status === 'failed' && (
				<div>
					<h2>Payment Failed!</h2>
				</div>
			)}

			{status === 'processing' && (
				<div>
					<h2>Payment Processing...</h2>
				</div>
			)}
		</div>
	)
}

