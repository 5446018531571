
import { Input, Button, Form, Modal, Select } from 'antd';
import { useSelector } from "react-redux";
import { newChatSendMsg, continusBlock } from "./utils";
import {chatMessageUpdate} from '../service/socketservice'
import { messageActions } from "../store/slice/message_slice";
import _, { parseInt } from "lodash";
import store from "../store/redux-store/root_store";
import { useDispatch } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import { useEffect, useState } from 'react';


const DropdownComponent = (props) => {
      const messageList = useSelector((state) => state.message);
      const dispatch = useDispatch();
      const currentData = props?.dropDownData?.information?.data && JSON.parse(props?.dropDownData?.information?.data);
      const dropDownArr = currentData?.getInputData?.options;
      const [selectedData, setSelectedData] = useState('');

      const handleInputChange = (event, selectedOption) => {
            setSelectedData(event)
            let daata = selectedOption?.option?.childId;
            const ansCaptureKeyValue = currentData?.getInputData?.contactSave
            newChatSendMsg("Text", event, "IN");
            store.dispatch(messageActions.dropDownList(''));
            if (currentData?.getInputData?.answerSave) {
                  let arr = [...messageList.inputEntity];
                  let index = messageList.inputEntity.findIndex((item) => {
                        return Object.keys(item).includes(ansCaptureKeyValue);
                  });
                  if (index !== -1) {
                        arr[index] = { ...arr[index], [ansCaptureKeyValue]: event };
                  } else {
                        arr = [...arr, { [ansCaptureKeyValue]: event }];
                  }
                  dispatch(messageActions.inputEntity(arr));
            }
            store.dispatch(messageActions.setAnsCaptureStatus(false));
            store.dispatch(messageActions.setAnsCaptureKey(''));
            let caroType = [];
            if (daata?.includes('carousel')) {
                  props?.dropDownData?.children.some((item) => {
                        if (item?.type === event) {
                              caroType?.push(item)
                        }
                  });
            }
            const data1 = {
                  session_id: messageList.uuid,
                  domain_id: messageList.personalisationAll?.domainId,
                  channelType: 'Chat',
                  response: "success",
                  btnValue: daata,
                  callFlowId: messageList.personalisationAll?.chatFlowId,
                  messageID: uuidv4(),
                  call_type: event,
                  carousal_type: caroType?.length>0 ? caroType : '',
                  ChatHistoryId: parseInt(sessionStorage.ChatHistoryId),
                  ChatDurationHistoryId: parseInt(sessionStorage?.ChatDurationHistoryId),
                  inputParams: messageList?.inputEntity
            };
            chatMessageUpdate(data1);
      }
      const dropOptions = dropDownArr?.map((option) => ({
            label: option.value,
            value: option.value,
            option,
      }));
      return (
            <>
                  <Select
                        className="fordropdowns"
                        //placeholder= {selectedData}
                        onChange={(value, selectedOption) => handleInputChange(value, selectedOption)}
                        options={dropOptions}
                        value={selectedData !== '' ?selectedData : `${currentData?.getInputData?.place_holder}`}
                  >
                  </Select>

            </>
      )
}

export default DropdownComponent;