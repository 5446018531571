const data =[
    {
      "country": "India",
      "phoneNumberLengthByCountry_phLength": 10
    },
    {
      "country": "China",
      "phoneNumberLengthByCountry_phLength": 13
    },
    {
      "country": "United States",
      "phoneNumberLengthByCountry_phLength": 10
    },
    {
      "country": "Indonesia",
      "phoneNumberLengthByCountry_phLength": 9
    },
    {
      "country": "Pakistan",
      "phoneNumberLengthByCountry_phLength": 10
    },
    {
      "country": "Nigeria",
      "phoneNumberLengthByCountry_phLength": 8
    },
    {
      "country": "Brazil",
      "phoneNumberLengthByCountry_phLength": 11
    },
    {
      "country": "Bangladesh",
      "phoneNumberLengthByCountry_phLength": 10
    },
    {
      "country": "Russia",
      "phoneNumberLengthByCountry_phLength": 10
    },
    {
      "country": "Mexico",
      "phoneNumberLengthByCountry_phLength": 10
    },
    {
      "country": "Japan",
      "phoneNumberLengthByCountry_phLength": 10
    },
    {
      "country": "Philippines",
      "phoneNumberLengthByCountry_phLength": 10
    },
    {
      "country": "Egypt",
      "phoneNumberLengthByCountry_phLength": 10
    },
    {
      "country": "Vietnam",
      "phoneNumberLengthByCountry_phLength": 9
    },
    {
      "country": "Iran",
      "phoneNumberLengthByCountry_phLength": 10
    },
    {
      "country": "Turkey",
      "phoneNumberLengthByCountry_phLength": 10
    },
    {
      "country": "Germany",
      "phoneNumberLengthByCountry_phLength": 10
    },
    {
      "country": "Thailand",
      "phoneNumberLengthByCountry_phLength": 9
    },
    {
      "country": "Tanzania",
      "phoneNumberLengthByCountry_phLength": 6
    },
    {
      "country": "United Kingdom",
      "phoneNumberLengthByCountry_phLength": 10
    },
    {
      "country": "South Africa",
      "phoneNumberLengthByCountry_phLength": 9
    },
    {
      "country": "Italy",
      "phoneNumberLengthByCountry_phLength": 10
    },
    {
      "country": "Kenya",
      "phoneNumberLengthByCountry_phLength": 10
    },
    {
      "country": "Myanmar",
      "phoneNumberLengthByCountry_phLength": 8
    },
    {
      "country": "Colombia",
      "phoneNumberLengthByCountry_phLength": 10
    },
    {
      "country": "South Korea",
      "phoneNumberLengthByCountry_phLength": 10
    },
    {
      "country": "Spain",
      "phoneNumberLengthByCountry_phLength": 9
    },
    {
      "country": "Algeria",
      "phoneNumberLengthByCountry_phLength": 9
    },
    {
      "country": "Afghanistan",
      "phoneNumberLengthByCountry_phLength": 9
    },
    {
      "country": "Poland",
      "phoneNumberLengthByCountry_phLength": 9
    },
    {
      "country": "Canada",
      "phoneNumberLengthByCountry_phLength": 10
    },
    {
      "country": "Ukraine",
      "phoneNumberLengthByCountry_phLength": 9
    },
    {
      "country": "Saudi Arabia",
      "phoneNumberLengthByCountry_phLength": 9
    },
    {
      "country": "Yemen",
      "phoneNumberLengthByCountry_phLength": 9
    },
    {
      "country": "Mozambique",
      "phoneNumberLengthByCountry_phLength": 12
    },
    {
      "country": "Ghana",
      "phoneNumberLengthByCountry_phLength": 9
    },
    {
      "country": "Peru",
      "phoneNumberLengthByCountry_phLength": 9
    },
    {
      "country": "Malaysia",
      "phoneNumberLengthByCountry_phLength": 7
    },
    {
      "country": "Nepal",
      "phoneNumberLengthByCountry_phLength": 10
    },
    {
      "country": "Venezuela",
      "phoneNumberLengthByCountry_phLength": 7
    },
    {
      "country": "Cameroon",
      "phoneNumberLengthByCountry_phLength": 9
    },
    {
      "country": "Niger",
      "phoneNumberLengthByCountry_phLength": 8
    },
    {
      "country": "Australia",
      "phoneNumberLengthByCountry_phLength": 9
    },
    {
      "country": "Syria",
      "phoneNumberLengthByCountry_phLength": 9
    },
    {
      "country": "Mali",
      "phoneNumberLengthByCountry_phLength": 8
    },
    {
      "country": "Taiwan",
      "phoneNumberLengthByCountry_phLength": 9
    },
    {
      "country": "Burkina Faso",
      "phoneNumberLengthByCountry_phLength": 8
    },
    {
      "country": "Sri Lanka",
      "phoneNumberLengthByCountry_phLength": 7
    },
    {
      "country": "Zambia",
      "phoneNumberLengthByCountry_phLength": 9
    },
    {
      "country": "Kazakhstan",
      "phoneNumberLengthByCountry_phLength": 10
    },
    {
      "country": "Chile",
      "phoneNumberLengthByCountry_phLength": 9
    },
    {
      "country": "Romania",
      "phoneNumberLengthByCountry_phLength": 10
    },
    {
      "country": "Chad",
      "phoneNumberLengthByCountry_phLength": 8
    },
    {
      "country": "Somalia",
      "phoneNumberLengthByCountry_phLength": 8
    },
    {
      "country": "Ecuador",
      "phoneNumberLengthByCountry_phLength": 9
    },
    {
      "country": "Guatemala",
      "phoneNumberLengthByCountry_phLength": 8
    },
    {
      "country": "Netherlands",
      "phoneNumberLengthByCountry_phLength": 9
    },
    {
      "country": "Cambodia",
      "phoneNumberLengthByCountry_phLength": 9
    },
    {
      "country": "Zimbabwe",
      "phoneNumberLengthByCountry_phLength": 9
    },
    {
      "country": "Benin",
      "phoneNumberLengthByCountry_phLength": 9
    },
    {
      "country": "Tunisia",
      "phoneNumberLengthByCountry_phLength": 8
    },
    {
      "country": "Belgium",
      "phoneNumberLengthByCountry_phLength": 9
    },
    {
      "country": "Dominican Republic",
      "phoneNumberLengthByCountry_phLength": 10
    },
    {
      "country": "Jordan",
      "phoneNumberLengthByCountry_phLength": 9
    },
    {
      "country": "Honduras",
      "phoneNumberLengthByCountry_phLength": 8
    },
    {
      "country": "Sweden",
      "phoneNumberLengthByCountry_phLength": 7
    },
    {
      "country": "Czech Republic",
      "phoneNumberLengthByCountry_phLength": 9
    },
    {
      "country": "Azerbaijan",
      "phoneNumberLengthByCountry_phLength": 9
    },
    {
      "country": "Greece",
      "phoneNumberLengthByCountry_phLength": 10
    },
    {
      "country": "Portugal",
      "phoneNumberLengthByCountry_phLength": 9
    },
    {
      "country": "Hungary",
      "phoneNumberLengthByCountry_phLength": 9
    },
    {
      "country": "United Arab Emirates",
      "phoneNumberLengthByCountry_phLength": 9
    },
    {
      "country": "Belarus",
      "phoneNumberLengthByCountry_phLength": 9
    },
    {
      "country": "Israel",
      "phoneNumberLengthByCountry_phLength": 9
    },
    {
      "country": "Togo",
      "phoneNumberLengthByCountry_phLength": 8
    },
    {
      "country": "Austria",
      "phoneNumberLengthByCountry_phLength": 10
    },
    {
      "country": "Switzerland",
      "phoneNumberLengthByCountry_phLength": 9
    },
    {
      "country": "Hong Kong",
      "phoneNumberLengthByCountry_phLength": 8
    },
    {
      "country": "Nicaragua",
      "phoneNumberLengthByCountry_phLength": 8
    },
    {
      "country": "Serbia",
      "phoneNumberLengthByCountry_phLength": 8
    },
    {
      "country": "Libya",
      "phoneNumberLengthByCountry_phLength": 10
    },
    {
      "country": "Paraguay",
      "phoneNumberLengthByCountry_phLength": 9
    },
    {
      "country": "El Salvador",
      "phoneNumberLengthByCountry_phLength": 8
    },
    {
      "country": "Singapore",
      "phoneNumberLengthByCountry_phLength": 8
    },
    {
      "country": "Denmark",
      "phoneNumberLengthByCountry_phLength": 8
    },
    {
      "country": "Slovakia",
      "phoneNumberLengthByCountry_phLength": 9
    },
    {
      "country": "Liberia",
      "phoneNumberLengthByCountry_phLength": 7
    },
    {
      "country": "Norway",
      "phoneNumberLengthByCountry_phLength": 8
    },
    {
      "country": "New Zealand",
      "phoneNumberLengthByCountry_phLength": 9
    },
    {
      "country": "Costa Rica",
      "phoneNumberLengthByCountry_phLength": 8
    },
    {
      "country": "Lebanon",
      "phoneNumberLengthByCountry_phLength": 7
    },
    {
      "country": "Ireland",
      "phoneNumberLengthByCountry_phLength": 9
    },
    {
      "country": "Oman",
      "phoneNumberLengthByCountry_phLength": 8
    },
    {
      "country": "Panama",
      "phoneNumberLengthByCountry_phLength": 8
    },
    {
      "country": "Kuwait",
      "phoneNumberLengthByCountry_phLength": 8
    },
    {
      "country": "Croatia",
      "phoneNumberLengthByCountry_phLength": 9
    },
    {
      "country": "Georgia",
      "phoneNumberLengthByCountry_phLength": 9
    },
    {
      "country": "Moldova",
      "phoneNumberLengthByCountry_phLength": 8
    },
    {
      "country": "Puerto Rico",
      "phoneNumberLengthByCountry_phLength": 10
    },
    {
      "country": "Bosnia and Herzegovina",
      "phoneNumberLengthByCountry_phLength": 8
    },
    {
      "country": "Jamaica",
      "phoneNumberLengthByCountry_phLength": 10
    },
    {
      "country": "Armenia",
      "phoneNumberLengthByCountry_phLength": 8
    },
    {
      "country": "Qatar",
      "phoneNumberLengthByCountry_phLength": 8
    },
    {
      "country": "Lithuania",
      "phoneNumberLengthByCountry_phLength": 8
    },
    {
      "country": "Gabon",
      "phoneNumberLengthByCountry_phLength": 7
    },
    {
      "country": "North Macedonia",
      "phoneNumberLengthByCountry_phLength": 8
    },
    {
      "country": "Latvia",
      "phoneNumberLengthByCountry_phLength": 8
    },
    {
      "country": "Trinidad and Tobago",
      "phoneNumberLengthByCountry_phLength": 10
    },
    {
      "country": "Bahrain",
      "phoneNumberLengthByCountry_phLength": 8
    },
    {
      "country": "Estonia",
      "phoneNumberLengthByCountry_phLength": 8
    },
    {
      "country": "Mauritius",
      "phoneNumberLengthByCountry_phLength": 8
    },
    {
      "country": "Cyprus",
      "phoneNumberLengthByCountry_phLength": 8
    },
    {
      "country": "Solomon Islands",
      "phoneNumberLengthByCountry_phLength": 7
    },
    {
      "country": "Luxembourg",
      "phoneNumberLengthByCountry_phLength": 9
    },
    {
      "country": "Montenegro",
      "phoneNumberLengthByCountry_phLength": 8
    },
    {
      "country": "Malta",
      "phoneNumberLengthByCountry_phLength": 8
    },
    {
      "country": "Maldives",
      "phoneNumberLengthByCountry_phLength": 7
    },
    {
      "country": "Belize",
      "phoneNumberLengthByCountry_phLength": 7
    },
    {
      "country": "Bahamas",
      "phoneNumberLengthByCountry_phLength": 10
    },
    {
      "country": "Guadeloupe",
      "phoneNumberLengthByCountry_phLength": 9
    },
    {
      "country": "Martinique",
      "phoneNumberLengthByCountry_phLength": 9
    },
    {
      "country": "French Guiana",
      "phoneNumberLengthByCountry_phLength": 9
    },
    {
      "country": "French Polynesia",
      "phoneNumberLengthByCountry_phLength": 6
    },
    {
      "country": "New Caledonia",
      "phoneNumberLengthByCountry_phLength": 6
    },
    {
      "country": "Barbados",
      "phoneNumberLengthByCountry_phLength": 10
    },
    {
      "country": "Samoa",
      "phoneNumberLengthByCountry_phLength": 5
    },
    {
      "country": "Saint Lucia",
      "phoneNumberLengthByCountry_phLength": 10
    },
    {
      "country": "Guam",
      "phoneNumberLengthByCountry_phLength": 10
    },
    {
      "country": "Kiribati",
      "phoneNumberLengthByCountry_phLength": 8
    },
    {
      "country": "Grenada",
      "phoneNumberLengthByCountry_phLength": 10
    },
    {
      "country": "Micronesia",
      "phoneNumberLengthByCountry_phLength": 7
    },
    {
      "country": "Aruba",
      "phoneNumberLengthByCountry_phLength": 7
    },
    {
      "country": "Saint Vincent and the Grenadines",
      "phoneNumberLengthByCountry_phLength": 10
    },
    {
      "country": "United States Virgin Islands",
      "phoneNumberLengthByCountry_phLength": 10
    },
    {
      "country": "Antigua and Barbuda",
      "phoneNumberLengthByCountry_phLength": 10
    },
    {
      "country": "Isle of Man",
      "phoneNumberLengthByCountry_phLength": 10
    },
    {
      "country": "Dominica",
      "phoneNumberLengthByCountry_phLength": 10
    },
    {
      "country": "Cayman Islands",
      "phoneNumberLengthByCountry_phLength": 10
    },
    {
      "country": "Bermuda",
      "phoneNumberLengthByCountry_phLength": 10
    },
    {
      "country": "Guernsey",
      "phoneNumberLengthByCountry_phLength": 10
    },
    {
      "country": "Greenland",
      "phoneNumberLengthByCountry_phLength": 6
    },
    {
      "country": "Faroe Islands",
      "phoneNumberLengthByCountry_phLength": 5
    },
    {
      "country": "Northern Mariana Islands",
      "phoneNumberLengthByCountry_phLength": 10
    },
    {
      "country": "Saint Kitts and Nevis",
      "phoneNumberLengthByCountry_phLength": 10
    },
    {
      "country": "Turks and Caicos Islands",
      "phoneNumberLengthByCountry_phLength": 10
    },
    {
      "country": "Sint Maarten",
      "phoneNumberLengthByCountry_phLength": 10
    },
    {
      "country": "American Samoa",
      "phoneNumberLengthByCountry_phLength": 10
    },
    {
      "country": "Marshall Islands",
      "phoneNumberLengthByCountry_phLength": 7
    },
    {
      "country": "British Virgin Islands",
      "phoneNumberLengthByCountry_phLength": 10
    },
    {
      "country": "Palau",
      "phoneNumberLengthByCountry_phLength": 7
    },
    {
      "country": "Cook Islands",
      "phoneNumberLengthByCountry_phLength": 5
    },
    {
      "country": "Anguilla",
      "phoneNumberLengthByCountry_phLength": 10
    },
    {
      "country": "Montserrat",
      "phoneNumberLengthByCountry_phLength": 10
    },
    {
      "country": "Niue",
      "phoneNumberLengthByCountry_phLength": 4
    },
    {
      "country": "Vatican City",
      "phoneNumberLengthByCountry_phLength": 10
    }
  ]

export default data