import React, { useEffect } from 'react'
import axios from 'axios';
import { useState } from 'react';
function Wix3ds  ()  {

    const[paymentStatus,setPaymentStatus] = useState("Processing")
    useEffect(async () => {
        let queryParams = new URLSearchParams(window.location.search);
        console.log("queryParams",queryParams)
        let status = queryParams.get("status");
         console.log("status",status)
         let wixpaymentObj = JSON.parse(localStorage.getItem("WixPayment"))
        if(status === "AUTHORIZED"){
            let transactionId = localStorage.getItem("transactionId")
            const data = {
                "accountId": "acc31e9b-96ab-4613-99d9-99696a4a9fb4",
                "transactionId":transactionId,
                "amount": wixpaymentObj.amount,
                "capturableAmount": wixpaymentObj.amount,
                "idempotencyKey": "wix",
                "externalCaptureId": "wix-product-purchase-001",
                "metadata": {},
                "domainId": sessionStorage.getItem("domainId")
              }

              const response = await axios.post('https://payment.worktual.co.uk/payment/v1/wix/captureTransaction', data);
              console.log("response.data.result",response?.data?.result)
              console.log("response.data.result.transaction?",response?.data?.result?.transaction)
              console.log("response.data.result.transaction?.captures?.status",response.data.result.transaction?.captures[0]?.status)
              if(response.data.result.transaction?.captures[0]?.status){
                setPaymentStatus("Success")
                window.parent.postMessage({ type: 'WIX_PAYMENT_IFRAME', message: response?.data.result.transaction }, '*');
              }
        }
    },[])
  return (
    <div>Payment {paymentStatus}</div>
  )
}


export default Wix3ds