//  prettier-ignore
export const config = () => {
      let BASE_URL;
      let CORESERVICE_URL;
      let CALLSERVICE_URL;
      let WORKTUALBOT;
      let OTHERBOT;
      let TEMPLATEBOT;
      let MERCHANT_ID;
      let SECRET_KEY;
      let PAYMENT_URL;
      let RETURN_URL;
      let ACCOUNT;
      let CONNECTOR_URL;
      let EMAIL_URL;
      let CREATE_SHOPIFY_CUSTOMER;
      let SHOPIFY_CONNECTION_LIST;
      let SHOPIFY_TOKEN_GENERATOR;
      let SHOPIFY_CHECKOUT;
      let MAGENTO_TOKEN_GENERATOR;
      let MAGENTO_PRODUCT_SEARCH;
      let MAGENTO_REFRESH_TOKEN;
      let BIGCOMMERCE_SEARCH_LIST;
      let BIGCOMMERCE_TOKEN_GENERATOR;
      let SHOPIFY_PRODUCT_SEARCH;
      let WORKTUAL_APPOINTMENT;
      let BIGCOMMERCE_GET_VARIANTS;
      let WIX_TOKEN_GENERATOR;
      let WIX_PRODUCT_SEARCH;
      let WIX_EXISTING_CUSTOMER;
      let WIX_CREATE_CUSTOMER;
      let WIX_ORDER_CREATION;
      let BIGCOMMERCE_EXISTING_CUSTOMER;
      let BIGCOMMERCE_CREATE_CUSTOMER;
      let BIGCOMMERCE_CREATE_ORDER;
      let ORDER_TOKEN_GENERATOR;
      let BIG_COMMERCE_PAYMENT;
      let MAGENTO_EXISTING_CUSTOMER;
      let MAGENTO_CREATE_CUSTOMER;
      let MAGENTO_CREATE_ORDER;
      let WIX_PRODUCT_SEARCH_ID;
      let WIX_CONTACT_UPDATE;
      let WIX_REFRESH_TOKEN_GENERATOR;
      let WOOCOMMERCE_TOKEN_GENERATOR;
      let WOOCOMMERCE_PRODUCT_SEARCH;
      let WOOCOMMERCE_GET_VARIANT;
      let WOOCOMMERCE_EXISTING_CUSTOMER;
      let ECOMMERCE_IMAGE_SEARCH;
      let WOOCOMMERECE_CREATE_CUSTOMER;
      let WOOCOMMERECE_UPDATE_CUSTOMER;
      let WOOCOMMERCE_ORDER_CREATION;
      let WOOCOMMERCE_PRODUCT_SEARCH_ID;
      let WOOCOMMERCE_OFFER_SEARCH;
      let CARE_HOME;
      if (process.env.REACT_APP_ENV === "development") {
            BASE_URL = "https://stageccaasapi.worktual.co.uk"
            CORESERVICE_URL = "https://stageccaasapi.worktual.co.uk/core_service/v1";
            CALLSERVICE_URL = "htps://stageccaasapi.worktual.co.uk/callservice/v1";
            WORKTUALBOT = "https://testvideo-meet.worktual.co.uk/chatbot";
            OTHERBOT = "https://testvideo-meet.worktual.co.uk/vectonechatbot";
            TEMPLATEBOT = "http://10.150.3.150:3005/ask";
            MERCHANT_ID = "Unitedfone"; // vectone  Unitedfone
            SECRET_KEY = "Tprbt9cpvb"; // KGCWbMuLRd  Tprbt9cpvb
            PAYMENT_URL = "https://pay.sandbox.realexpayments.com/pay";
            RETURN_URL = "https://liveurapi.worktual.co.uk/vectone-myaccount/v1/user/test_callback";
            ACCOUNT = "internet"; // uk internet
            CONNECTOR_URL="https://qaccaasapi.worktual.co.uk/connector/v1"
            EMAIL_URL = "https://qaccaasapi.worktual.co.uk/emailService/v1"
            WORKTUAL_APPOINTMENT="https://stagemarketingapi.worktual.co.uk/marketing_automation/v1"
            CARE_HOME="https://carehomeapi.audracare.co.uk/homecare_service/v1"
      } else if (process.env.REACT_APP_ENV === "production") {
            BASE_URL = "https://ccaasapi.worktual.co.uk";
            CORESERVICE_URL = "https://ccaasapi.worktual.co.uk/core_service/v1";
            CALLSERVICE_URL = "https://ccaasapi.worktual.co.uk/callservice/v1";
            WORKTUALBOT = "https://testvideo-meet.worktual.co.uk/chatbot";
            OTHERBOT = "https://testvideo-meet.worktual.co.uk/vectonechatbot";
            TEMPLATEBOT = "https://testvideo-meet.worktual.co.uk/ask";
            MERCHANT_ID = "vectone"; // vectone  Unitedfone
            SECRET_KEY = "KGCWbMuLRd"; // KGCWbMuLRd  Tprbt9cpvb
            PAYMENT_URL = "https://pay.realexpayments.com/pay";
            RETURN_URL = "https://liveurapi.worktual.co.uk/vectone-myaccount/v1/user/test_callback";
            ACCOUNT = "uk"; // uk internet
            CONNECTOR_URL="https://ccaasapi.worktual.co.uk/connector/v1"
            EMAIL_URL = "https://ccaasapi.worktual.co.uk/emailService/v1"
            CREATE_SHOPIFY_CUSTOMER = "https://ccaasapi.worktual.co.uk/connector/v1/shopify/customer"
            SHOPIFY_CONNECTION_LIST = 'https://ccaasapi.worktual.co.uk/connector/v1/shopify/company';
            SHOPIFY_TOKEN_GENERATOR = 'https://ccaasapi.worktual.co.uk/connector/v1/shopify/productTitle'
            SHOPIFY_CHECKOUT = 'https://ccaasapi.worktual.co.uk/connector/v1/shopify/checkout';
            MAGENTO_TOKEN_GENERATOR= 'https://ccaasapi.worktual.co.uk/connector/v1/magento/shopList';
            MAGENTO_PRODUCT_SEARCH= 'https://ccaasapi.worktual.co.uk/connector/v1/magento/productsTitle';
            MAGENTO_REFRESH_TOKEN = 'https://ccaasapi.worktual.co.uk/connector/v1/magento/refresh_token';
            BIGCOMMERCE_SEARCH_LIST = 'https://ccaasapi.worktual.co.uk/connector/v1/bigcommerce/catalog/productTitle'
            BIGCOMMERCE_TOKEN_GENERATOR = 'https://ccaasapi.worktual.co.uk/connector/v1/bigcommerce/shopList'
            SHOPIFY_PRODUCT_SEARCH = 'https://ccaasapi.worktual.co.uk/connector/v1/shopify/product_search'
            WORKTUAL_APPOINTMENT="https://stagemarketingapi.worktual.co.uk/marketing_automation/v1"
            BIGCOMMERCE_GET_VARIANTS = 'https://ccaasapi.worktual.co.uk/connector/v1/bigcommerce/catalog/products'
            WIX_TOKEN_GENERATOR = 'https://ccaasapi.worktual.co.uk/connector/v1/wix/shopList';
            WIX_PRODUCT_SEARCH = 'https://ccaasapi.worktual.co.uk/connector/v1/wix/product'
            WIX_EXISTING_CUSTOMER = 'https://ccaasapi.worktual.co.uk/connector/v1/wix/contactEmail';
            WIX_CREATE_CUSTOMER='https://ccaasapi.worktual.co.uk/connector/v1/wix/contact';
            WIX_ORDER_CREATION = 'https://ccaasapi.worktual.co.uk/connector/v1/wix/order';
            BIGCOMMERCE_EXISTING_CUSTOMER = 'https://ccaasapi.worktual.co.uk/connector/v1/bigcommerce/customerEmail'
            BIGCOMMERCE_CREATE_CUSTOMER = 'https://ccaasapi.worktual.co.uk/connector/v1/bigcommerce/customer'
            BIGCOMMERCE_CREATE_ORDER= 'https://ccaasapi.worktual.co.uk/connector/v1/bigcommerce/order';
            ORDER_TOKEN_GENERATOR='https://ccaasapi.worktual.co.uk/connector/v1/bigcommerce/payment/accessToken'
            BIG_COMMERCE_PAYMENT ='https://ccaasapi.worktual.co.uk/connector/v1/bigcommerce/processPayment'
            MAGENTO_EXISTING_CUSTOMER = 'https://ccaasapi.worktual.co.uk/connector/v1/magento/customerSearch'
            MAGENTO_CREATE_CUSTOMER = 'https://ccaasapi.worktual.co.uk/connector/v1/magento/customer'
            MAGENTO_CREATE_ORDER = 'https://ccaasapi.worktual.co.uk/connector/v1/magento/order'
            WIX_PRODUCT_SEARCH_ID = 'https://ccaasapi.worktual.co.uk/connector/v1/wix/product_search'
            WIX_CONTACT_UPDATE = 'https://ccaasapi.worktual.co.uk/connector/v1/wix/contact'
            WIX_REFRESH_TOKEN_GENERATOR = 'https://ccaasapi.worktual.co.uk/connector/v1/wix/refresh_token'
            WOOCOMMERCE_TOKEN_GENERATOR= 'https://ccaasapi.worktual.co.uk/connector/v1/woocommerce/shopList';
            WOOCOMMERCE_PRODUCT_SEARCH = 'https://ccaasapi.worktual.co.uk/connector/v1/woocommerce/productsTitle'
            WOOCOMMERCE_GET_VARIANT = 'https://ccaasapi.worktual.co.uk/connector/v1/woocommerce/products'
            WOOCOMMERCE_EXISTING_CUSTOMER = 'https://ccaasapi.worktual.co.uk/connector/v1/woocommerce/customerEmail'
            WOOCOMMERECE_CREATE_CUSTOMER = 'https://ccaasapi.worktual.co.uk/connector/v1/woocommerce/customer'
            // ECOMMERCE_IMAGE_SEARCH = 'http://46.43.144.145:7050/image_search'
            ECOMMERCE_IMAGE_SEARCH = 'https://scraping.worktual.co.uk/image_search';
            WOOCOMMERECE_UPDATE_CUSTOMER = 'https://ccaasapi.worktual.co.uk/connector/v1/woocommerce/customer'
            WOOCOMMERCE_ORDER_CREATION = 'https://ccaasapi.worktual.co.uk/connector/v1/woocommerce/order'
            WOOCOMMERCE_PRODUCT_SEARCH_ID = 'https://ccaasapi.worktual.co.uk/connector/v1/woocommerce/productSearchByIds'
            WOOCOMMERCE_OFFER_SEARCH= 'https://ccaasapi.worktual.co.uk/connector/v1/woocommerce/products/sale'
            CARE_HOME="https://carehomeapi.audracare.co.uk/homecare_service/v1"
      } else if (process.env.REACT_APP_ENV === "qa") {
            BASE_URL = "https://qaccaasapi.worktual.co.uk";
            CORESERVICE_URL = "https://qaccaasapi.worktual.co.uk/core_service/v1";
            CALLSERVICE_URL = "https://qaccaasapi.worktual.co.uk/callservice/v1";
            WORKTUALBOT = "https://testvideo-meet.worktual.co.uk/qaworktualchatbot";
            OTHERBOT = "https://testvideo-meet.worktual.co.uk/vectonechatbot";
            TEMPLATEBOT = "https://testvideo-meet.worktual.co.uk/ask";
            MERCHANT_ID = "vectone"; // vectone  Unitedfone
            SECRET_KEY = "KGCWbMuLRd"; // KGCWbMuLRd  Tprbt9cpvb
            PAYMENT_URL = "https://pay.sandbox.realexpayments.com/pay";
            RETURN_URL = "https://liveurapi.worktual.co.uk/vectone-myaccount/v1/user/test_callback";
            ACCOUNT = "uk"; // uk internet
            CONNECTOR_URL="https://qaccaasapi.worktual.co.uk/connector/v1"
            EMAIL_URL = "https://qaccaasapi.worktual.co.uk/emailService/v1"
            CREATE_SHOPIFY_CUSTOMER = "https://qaccaasapi.worktual.co.uk/connector/v1/shopify/customer";
            SHOPIFY_CONNECTION_LIST = 'https://qaccaasapi.worktual.co.uk/connector/v1/shopify/company';
            SHOPIFY_TOKEN_GENERATOR = 'https://qaccaasapi.worktual.co.uk/connector/v1/shopify/productTitle'
            SHOPIFY_CHECKOUT = 'https://qaccaasapi.worktual.co.uk/connector/v1/shopify/checkout'
            MAGENTO_TOKEN_GENERATOR= 'https://qaccaasapi.worktual.co.uk/connector/v1/magento/shopList';
            MAGENTO_PRODUCT_SEARCH= 'https://qaccaasapi.worktual.co.uk/connector/v1/magento/productsTitle';
            MAGENTO_REFRESH_TOKEN = 'https://qaccaasapi.worktual.co.uk/connector/v1/magento/refresh_token';
            BIGCOMMERCE_TOKEN_GENERATOR = 'https://ccaasapi.worktual.co.uk/connector/v1/bigcommerce/shopList'
            SHOPIFY_PRODUCT_SEARCH = 'https://ccaasapi.worktual.co.uk/connector/v1/shopify/product_search'
            WORKTUAL_APPOINTMENT="https://stagemarketingapi.worktual.co.uk/marketing_automation/v1"
            BIGCOMMERCE_SEARCH_LIST = 'https://qaccaasapi.worktual.co.uk/connector/v1/bigcommerce/catalog/productTitle'
            BIGCOMMERCE_GET_VARIANTS = 'https://qaccaasapi.worktual.co.uk/connector/v1/bigcommerce/catalog/products'
            WIX_TOKEN_GENERATOR = 'https://qaccaasapi.worktual.co.uk/connector/v1/wix/shopList'
            WIX_PRODUCT_SEARCH = 'https://qaccaasapi.worktual.co.uk/connector/v1/wix/product';
            WIX_EXISTING_CUSTOMER = 'https://qaccaasapi.worktual.co.uk/connector/v1/wix/contactEmail';
            WIX_CREATE_CUSTOMER='https://qaccaasapi.worktual.co.uk/connector/v1/wix/contact';
            WIX_ORDER_CREATION = 'https://qaccaasapi.worktual.co.uk/connector/v1/wix/order';
            BIGCOMMERCE_EXISTING_CUSTOMER = 'https://qaccaasapi.worktual.co.uk/connector/v1/bigcommerce/customerEmail'
            BIGCOMMERCE_CREATE_CUSTOMER = 'https://qaccaasapi.worktual.co.uk/connector/v1/bigcommerce/customer'
            BIGCOMMERCE_CREATE_ORDER= 'https://qaccaasapi.worktual.co.uk/connector/v1/bigcommerce/order';
            ORDER_TOKEN_GENERATOR='https://qaccaasapi.worktual.co.uk/connector/v1/bigcommerce/payment/accessToken'
            BIG_COMMERCE_PAYMENT ='https://qaccaasapi.worktual.co.uk/connector/v1/bigcommerce/processPayment'
            MAGENTO_EXISTING_CUSTOMER = 'https://qaccaasapi.worktual.co.uk/connector/v1/magento/customerSearch'
            MAGENTO_CREATE_CUSTOMER = 'https://qaccaasapi.worktual.co.uk/connector/v1/magento/customer'
            MAGENTO_CREATE_ORDER = 'https://qaccaasapi.worktual.co.uk/connector/v1/magento/order'
            WIX_PRODUCT_SEARCH_ID = 'https://qaccaasapi.worktual.co.uk/connector/v1/wix/product_search'
            WIX_CONTACT_UPDATE = 'https://qaccaasapi.worktual.co.uk/connector/v1/wix/contact'
            WIX_REFRESH_TOKEN_GENERATOR = 'https://qaccaasapi.worktual.co.uk/connector/v1/wix/refresh_token'
            WOOCOMMERCE_TOKEN_GENERATOR= 'https://qaccaasapi.worktual.co.uk/connector/v1/woocommerce/shopList';
            WOOCOMMERCE_PRODUCT_SEARCH = 'https://qaccaasapi.worktual.co.uk/connector/v1/woocommerce/productsTitle'
            WOOCOMMERCE_GET_VARIANT = 'https://qaccaasapi.worktual.co.uk/connector/v1/woocommerce/products';
            WOOCOMMERCE_EXISTING_CUSTOMER = 'https://qaccaasapi.worktual.co.uk/connector/v1/woocommerce/customerEmail' 
            WOOCOMMERECE_CREATE_CUSTOMER = 'https://qaccaasapi.worktual.co.uk/connector/v1/woocommerce/customer'
            ECOMMERCE_IMAGE_SEARCH = 'http://46.43.144.145:7050/image_search';
            ECOMMERCE_IMAGE_SEARCH = 'https://scraping.worktual.co.uk/image_search';
            WOOCOMMERECE_UPDATE_CUSTOMER = 'https://qaccaasapi.worktual.co.uk/connector/v1/woocommerce/customer'
            WOOCOMMERCE_ORDER_CREATION = 'https://qaccaasapi.worktual.co.uk/connector/v1/woocommerce/order'
            WOOCOMMERCE_PRODUCT_SEARCH_ID = 'https://ccaasapi.worktual.co.uk/connector/v1/woocommerce/productSearchByIds'
            WOOCOMMERCE_OFFER_SEARCH= 'https://qaccaasapi.worktual.co.uk/connector/v1/woocommerce/products/sale'
            CARE_HOME="https://carehomeapi.audracare.co.uk/homecare_service/v1"
      } else {
            BASE_URL = "https://ccaasapi.worktual.co.uk";
            CORESERVICE_URL = "https://ccaasapi.worktual.co.uk/core_service/v1";
            CALLSERVICE_URL = "http://localhost:5007/callservice/v1";
            WORKTUALBOT = "http://10.150.3.150:8088/chatbot";
            OTHERBOT = "http://10.150.3.150:8088/vectonechatbot";
            TEMPLATEBOT = "https://testvideo-meet.worktual.co.uk/ask";
            MERCHANT_ID = "Unitedfone"; // vectone  Unitedfone
            SECRET_KEY = "Tprbt9cpvb"; // KGCWbMuLRd  Tprbt9cpvb
            PAYMENT_URL = "https://pay.realexpayments.com/pay";
            RETURN_URL = "https://liveurapi.worktual.co.uk/vectone-myaccount/v1/user/test_callback";
            ACCOUNT = "internet"; // uk internet
            CONNECTOR_URL="https://qaccaasapi.worktual.co.uk/connector/v1"
            EMAIL_URL = "https://qaccaasapi.worktual.co.uk/emailService/v1"
            CARE_HOME="https://carehomeapi.audracare.co.uk/homecare_service/v1"
      }
      
      return {
            CORESERVICE_URL,
            CALLSERVICE_URL,
            BASE_URL,
            WORKTUALBOT,
            OTHERBOT,
            TEMPLATEBOT,
            MERCHANT_ID,
            SECRET_KEY,
            PAYMENT_URL,
            RETURN_URL,
            ACCOUNT,
            CONNECTOR_URL,
            EMAIL_URL,
            CREATE_SHOPIFY_CUSTOMER,
            SHOPIFY_CONNECTION_LIST,
            SHOPIFY_TOKEN_GENERATOR,
            SHOPIFY_CHECKOUT,
            SHOPIFY_PRODUCT_SEARCH,
            WORKTUAL_APPOINTMENT,
            MAGENTO_TOKEN_GENERATOR,
            MAGENTO_PRODUCT_SEARCH,
            MAGENTO_REFRESH_TOKEN,
            BIGCOMMERCE_SEARCH_LIST,
            BIGCOMMERCE_TOKEN_GENERATOR,
            SHOPIFY_PRODUCT_SEARCH,
            BIGCOMMERCE_GET_VARIANTS,
            WIX_TOKEN_GENERATOR,
            WIX_PRODUCT_SEARCH,
            WIX_EXISTING_CUSTOMER,
            WIX_CREATE_CUSTOMER,
            WIX_ORDER_CREATION,
            BIGCOMMERCE_EXISTING_CUSTOMER,
            BIGCOMMERCE_CREATE_CUSTOMER,
            BIGCOMMERCE_CREATE_ORDER,
            ORDER_TOKEN_GENERATOR,
            BIG_COMMERCE_PAYMENT,
            MAGENTO_EXISTING_CUSTOMER,
            MAGENTO_CREATE_CUSTOMER,
            MAGENTO_CREATE_ORDER,
            WIX_PRODUCT_SEARCH_ID,
            WIX_CONTACT_UPDATE,
            WIX_REFRESH_TOKEN_GENERATOR,
            WOOCOMMERCE_TOKEN_GENERATOR,
            WOOCOMMERCE_PRODUCT_SEARCH,
            WOOCOMMERCE_GET_VARIANT,
            WOOCOMMERCE_EXISTING_CUSTOMER,
            ECOMMERCE_IMAGE_SEARCH,
            WOOCOMMERECE_CREATE_CUSTOMER,
            WOOCOMMERECE_UPDATE_CUSTOMER,
            WOOCOMMERCE_ORDER_CREATION,
            WOOCOMMERCE_PRODUCT_SEARCH_ID,
            WOOCOMMERCE_OFFER_SEARCH,
            CARE_HOME
      };
};
