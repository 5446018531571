import io from "socket.io-client";
import store from "../store/redux-store/root_store";
import { messageActions } from "../store/slice/message_slice";
import { liveTrackerActions } from "../store/slice/live_tracker";
import { config } from "../base/constants";
import { v4 as uuidv4 } from "uuid";
import { agentDomainStatus, storeSupervisorExt, getDispostionRes, getLiveTrackerData, receiveChatMessage, chatResponse, initiateBot, availableagent, requestAccept, requestDecline, getqueueDetails, getChatDurationId, agentDisconnect, contactStorde, paymentStatus, initgoStream, socketSession, isJSON } from "../chatbot/utils"
import { message } from "antd";
import { useSelector } from "react-redux";
let sessionId = sessionStorage.getItem("sessionUniqueId");
let socketId = ''
if (sessionId === undefined || sessionId === null || sessionId === '') {
  sessionId = `${new Date().getTime()}`;
  sessionStorage.setItem("sessionUniqueId", sessionId)
}

store.dispatch(messageActions.uuid(sessionId));
store.dispatch(liveTrackerActions.uuid(sessionId))
console.log("Socket Service calling", sessionId);

export const socket = io(config().BASE_URL, {
  // export var socket = io("http://localhost:5008", {
  path: "/ccascall-service",
  auth: {
    token: "",
    id: sessionId,
  },
});

socket.on("connect", (data) => {
  console.log("conncted >>>>>>>", socket.id);
  socketId = sessionId;
  socketSession(sessionId)
  console.log("succes >>>>>", socket)
})
socket.on("userStatusUpdated", (data) => {
  console.log("new userStatusUpdated >>>>", data);
});

socket.on("waitingQueueUpdate", (data) => {
  console.log("waitingQueueUpdate >>>>", data);
})
socket.on("get_queue_details", (data) => {
  getqueueDetails(data);
});

socket.on("chat_response", (message) => {
  store.dispatch(messageActions.lottieProcess(false));
  chatResponse(message);
});

socket.on("OneToOneChatReceive", (message) => {
  //const receiveId =localStorage.getItem('uuid')
  const receiveId = sessionId;
  if (receiveId === message?.from || receiveId === message?.to || receiveId === message?.sessionId) {
    receiveChatMessage(message);
  }
});
socket.on("agentDisconnected", (message) => {
  agentDisconnect(message);
});


socket.on("airesponse", (message) => {
  const messageList = store.getState().message;
  const { domainId, chatFlowId } = store.getState().message?.personalisationAll;
  let chatHistoryId = sessionStorage.getItem("ChatHistoryId");
  const storeIntentVal = messageList?.questionAnswerList?.map((elem) => JSON.parse(elem?.intent))?.flat();
  if (message?.stream_finished === 0 && message?.trigger_form !== true && !messageList?.simOrderSts) {
    initgoStream(message);
  } else {
    initiateBot(message);
  }
  if (isJSON(message?.ans_intent) && storeIntentVal.includes(JSON.parse(message?.ans_intent)[0]) && message?.stream_finished === 1) {
    const data = {
      IntentName: JSON.parse(message.ans_intent)[0],
      IntentCount: 1,
      domainId: domainId,
      ChatFlowId: chatFlowId,
      ChatHistoryId: chatHistoryId
    }
    socket.emit("chatIntentEvent", data);
  }
});

socket.on('supervisor_ext_response', (data) => {
  storeSupervisorExt(data)
})

socket.on("availableagent", (data) => {
  store.dispatch(messageActions.lottieProcess(false));
  availableagent(data);
  socket.on("webChatConnected", (data) => { });
})
socket.on("requestAccept", (message) => {
  requestAccept(message);
});
socket.on("requestDecline", (message) => {
  requestDecline(message);
});
socket.on("botConnectedResponse", (message) => {
  getChatDurationId(message);
});

socket.on('get_domain_dispostionId', (result) => {
  getDispostionRes(result)
})

socket.on("getLiveTrackStatus", () => {
  getLiveTrackerData()
});
socket.on('responseAgentStatus', (message) => {
  agentDomainStatus(message)
})
socket.on('paymentstatus', (message) => {
  // paymentStatus(message);

})

export const emitMessage = (channelname, payload) => {
  socket.emit(channelname, payload)
}
export const updateChatSession = (sessionId) => {
  socket.emit("updateChatSession", {
    sessionId: sessionId
  });
}
export const joinWebChat = (data) => {
  socket.emit("joinWebChat", data);
}
export const storeCustomerData = (data) => {
  socket.emit("store_customer_data", data);
}
export const botConnectedTime = (data) => {

  socket.emit("botConnectedTime", data);
}
export const chatMessageUpdate = (data) => {
  if (data?.btnValue !== '' && data?.btnValue !== null) {
    store.dispatch(messageActions.currentTranferBlock(data?.btnValue))
  }
  store.dispatch(messageActions.templateKeywordList([]))
  socket.emit("chat_message_update", JSON.stringify(data));
  store.dispatch(messageActions.lottieProcess(true));
}
export const jointoLiveAgent = (data) => {
  socket.emit("join_to_live_agent", data);
}

export const oneToOneChatSend = (data) => {
  socket.emit("OneToOneChatSend", data);
}
export const sendChatBotReq = (data) => {
  socket.emit("send_chatbot_req", JSON.stringify(data));
}
export const updateThumbsUp = (data) => {
  socket.emit("updateThumbsUp", data);
}

export const botDisconnectedTime = (data) => {
  socket.emit("botDisconnectTime", data);
}

export const siteTracking = (data) => {
  socket.emit('liveTracking', data)
}

export const dummyEmitter = () => {
  socket.emit('refershLiveTracker')
}

export const getAgentStatus = (data) => {
  socket.emit('requestAgentStatus', data)
}

export const getSupervisorExt = (data) => {
  socket.emit('getSupervisorExt', data)
}

export const leadCrmContact = (data) => {
  socket.emit('lead_contact_update', data)
}
export const getunresponsiveQA = (data) => {
  socket.emit('unResponsiveQuesAns', data)
}
export const sendEmailTranscript = (data) => {
  socket.emit('sendEmailTranscript', data)
}

export const updateCallbackData = (data) => {
  socket.emit('update_callback_data', data)
}

export const dashboardWebChatCallBack = (data) => {
  socket.emit('dashboard_chat_callback', data)
}

export const updateEndSession = (data) => {
  socket.emit('update_end_session', data)
}

export const missedCallDashboard = (data) => {
  socket.emit('missed_call_dashboard', data)
}

export const abandonedChatDashboard = (data) => {
  socket.emit('abandoned_chat_dashboard', data)
}

export const inboundChatDashboard = (data) => {
  socket.emit('inbound_chat_dashboard', data)
}
