import { MESSAGES } from "../redux-store/reducer_names";
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  uuid: '',
  agentSessionAvaiableStatus: false,
  botSessionAvaiableStatus: false
};

const liveTrackerSice = createSlice({
  name: MESSAGES,
  initialState,
  reducers: {
    uuid: (state, { payload }) => {
      state.uuid = payload;
    },
    setAgentSessionAvaiableStatus: (state, { payload }) =>{
      state.agentSessionAvaiableStatus = payload
    },
    setBotSessionAvaiableStatus: (state, { payload }) =>{
      state.botSessionAvaiableStatus = payload
    },
    reset: () => initialState,
  },
});

export const liveTrackerActions = liveTrackerSice.actions;
export default liveTrackerSice.reducer;
