import React, { useEffect, useState } from "react";
import ChatBot from "./index";
import ChatScreen from "./chatscreen";
import { useDispatch, useSelector } from "react-redux";
import { messageActions } from "../store/slice/message_slice";
import FooterLogo from "./../images/chatbot-footerlogo.svg";
import "../chatPreview.scss";

function ConsentForm() {
  const messageList = useSelector((state) => state.message);
  console.log("messageList",messageList)
  const [showDeclineMessage, setShowDeclineMessage] = useState(false);
  const [consentMessage,setConsentMessage] = useState()
  const dispatch = useDispatch();

  const handleOptin = () => {
    dispatch(messageActions.isConsentForm(false));
    messageList.chatVisible ? <ChatBot /> : <ChatScreen />;
  };
  const handleOptout = () => {
    setShowDeclineMessage(true);
  };

  const handleprivacyPolicy = () => {
    window.open(messageList.personalisation.privacyPolicyLink, "_blank")
  }

 

  return (
    <div className="chatBody">
    <div className="scroller chatbotformtitle">
      {showDeclineMessage ? (
        <div>
            <h4>Data Privacy Bot</h4>
          <p>{messageList?.personalisation?.optOutConfirmation}</p>
          <a href={messageList?.personalisation?.privacyPolicyLink}  target="_blank">
            View our privacy policy
          </a>
        </div>
      ) : (
        <div className="formchat">
            <h4>Data Privacy Bot</h4>
          <div dangerouslySetInnerHTML={{ __html: messageList?.personalisation?.consentMessage.replaceAll('[Company Name]',messageList?.domainDetails[0]?.companyName)}} />
          <a href={messageList?.personalisation?.privacyPolicyLink ?? ""} target="_blank">
            View our privacy policy
          </a>
          <br></br>
          <div className="btnSection">
          <button className="btnGreen"  onClick={handleOptin}>{messageList?.personalisation?.optInButton}</button>
          <button className="btnCancel"  onClick={handleOptout}> {messageList?.personalisation?.optOutButton}  </button>
          </div>
        </div>
      )}
    </div>
    <div className="footercopyright footertopspace" >
        <div className="footercopys">
          <p>Powered by</p>
          <img src={FooterLogo} alt="" onClick={()=>window.open('https://worktual.co.uk/',"_blank")}/>
          
        </div>
          <p>By chatting, you consent to this chat being stored according to our privacy policy</p>
        </div>
    </div>
  );
}

export default ConsentForm;
